import * as React from "react"
import "typeface-noticia-text"
import "typeface-open-sans"
import styled from "styled-components"

import Sidebar from "./sidebar"
import "./layout.css"

const LayoutContainer = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 62px 40px 24px;

  footer {
    margin-top: 56px;

    p {
      font-size: 13px;
      text-align: center;
      margin: 0;
      font-family: "Open Sans", sans-serif;
      line-height: 1.7;
    }
  }

  @media only screen and (min-width: 600px) {
    padding-top: 80px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 1440px;
    padding: 100px 72px 40px;
    display: grid;
    grid-template-columns: 1fr 2fr;

    footer {
      display: none;
    }

    main {
      max-width: 800px;
    }
  }
`

const Layout = ({ children, context }) => {
  return (
    <>
      <LayoutContainer>
        <Sidebar context={context} />
        <div className="scroll-helper"></div>
        <main>{children}</main>
        <footer>
          <p>
            Diseño y desarrollo por{" "}
            <a
              className="link"
              href="https://estudioobliquo.com/"
              target="_blank"
              style={{ display: "inline" }}
            >
              Estudio Obliquo
            </a>
          </p>
          <p>Todos los derechos reservados © {new Date().getFullYear()}</p>
        </footer>
      </LayoutContainer>
    </>
  )
}

export default Layout
