import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

const SidebarContainer = styled.div`
  margin-bottom: 40px;
  display: ${({ context }) => (context === "post" ? "none" : "block")};

  .image-container {
    border-radius: 50%;
    overflow: hidden;
    height: 150px;
    width: 150px;
    margin-bottom: 24px;
  }

  .footer-text {
    display: none;
  }

  .home-link {
    color: var(--text-color);
    transition: color 0.2s;
    text-decoration: none;

    &:hover {
      color: var(--main-color-dark);
      transition: color 0.2s;
    }
  }

  @media only screen and (min-width: 1200px) {
    display: block;
    position: fixed;
    max-width: 275px;

    .image-container {
      height: 120px;
      width: 120px;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 8px;
    }

    p.bio,
    a.link {
      font-size: 17px;
      line-height: 1.5;
    }

    .footer-text {
      display: block;
      margin-top: 72px;

      p,
      a {
        font-size: 13px;
        font-family: "Open Sans", sans-serif;
        margin: 0;
        line-height: 1.7;
      }
    }
  }
`

const Sidebar = ({ context }) => (
  <StaticQuery
    query={graphql`
      query SidebarQuery {
        markdownRemark(frontmatter: { textTitle: { eq: "biografia" } }) {
          frontmatter {
            textContent
          }
        }
      }
    `}
    render={data => (
      <SidebarContainer context={context}>
        <div className="image-container">
          <StaticImage
            src="../images/profile.png"
            width={300}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>
        <Link to="/" className="home-link">
          <h2>Luis Alberto Boh</h2>
        </Link>
        <p className="bio">{data.markdownRemark.frontmatter.textContent}</p>
        <a className="link" href="mailto:lboh@bohasociados.com">
          lboh@bohasociados.com
        </a>
        <a className="link" href="https://twitter.com/LuisBoh1">
          Twitter
        </a>
        <div className="footer-text">
          <p>
            Diseño y desarrollo por{" "}
            <a
              className="link"
              href="https://estudioobliquo.com/"
              target="_blank"
              style={{ display: "inline" }}
            >
              Estudio Obliquo
            </a>
          </p>
          <p>Todos los derechos reservados © {new Date().getFullYear()}</p>
        </div>
      </SidebarContainer>
    )}
  />
)

export default Sidebar
